import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './CategoryFilterPlain.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class CategoryFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true, 
      showLiveTalent: false, 
      showMusicAudio: false, 
      showMarketing: false, 
      showLocalServices: false, 
      showGraphicsDesign: false, 
      showBusiness: false };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  selectParentOption(option, e) {
    const { onSelect } = this.props;
    const queryParamName = getQueryParamName('pub_parentCategory');
    onSelect({ [queryParamName]: option });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }




  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });


    const classes = classNames(rootClassName || css.root, className);

    const businessChildCategories = options.filter(obj => {
      return obj.category === "business"
    });

    const graphicsDesignChildCategories = options.filter(obj => {
      return obj.category === "graphics-and-design"
    });
    const localServicesChildCategories = options.filter(obj => {
      return obj.category === "local-services"
    });
    const marketingChildCategories = options.filter(obj => {
      return obj.category === "marketing"
    });
    const musicAudioChildCategories = options.filter(obj => {
      return obj.category === "music-and-audio"
    });
    const liveTalentChildCategories = options.filter(obj => {
      return obj.category === "live-talent"
    });


    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.label}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        <div id="categoryMenu" className={optionsContainerClass}>
          
          <button className={css.labelButton} onClick={() => {
            this.setState({       showLiveTalent: false, 
              showMusicAudio: false, 
              showMarketing: false, 
              showLocalServices: false, 
              showGraphicsDesign: false, 
              showBusiness: true  });
            this.selectParentOption('business');
            this.selectOption(null);

            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Business</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showBusiness} />
              </span>
            </span>
          </button>
          {this.state.showBusiness ? (
            businessChildCategories.map(option => {
              // check if this option is selected
              const selected = initialValue === option.key;
              const optionClass = classNames(css.option, {
                [css.optionHighlight]: selected && useHighlight,
              });
              // menu item selected bullet or border class
              const optionBorderClass = useBorder
                ? classNames({
                    [css.optionBorderSelected]: selected,
                    [css.optionBorder]: !selected,
                  })
                : null;
              return (
                <button
                  key={option.key}
                  className={optionClass}
                  onClick={() => this.selectOption(option.key)}
                >
                  {useBorder ? <span className={optionBorderClass} /> : null}
                  {option.label}
                </button>
              );
            })
          ): null}

          <button className={css.labelButton} onClick={() => {
            this.setState({       showLiveTalent: false, 
              showMusicAudio: false, 
              showMarketing: false, 
              showLocalServices: false, 
              showGraphicsDesign: true, 
              showBusiness: false  });
            this.selectParentOption('graphics-and-design');
            this.selectOption(null);


            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Graphics & Design</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showGraphicsDesign} />
              </span>
            </span>
          </button>
          { this.state.showGraphicsDesign ?(graphicsDesignChildCategories.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
          })
        ): null}

          <button className={css.labelButton} onClick={() => {
            this.setState({       showLiveTalent: false, 
              showMusicAudio: false, 
              showMarketing: false, 
              showLocalServices: true, 
              showGraphicsDesign: false, 
              showBusiness: false  });
            this.selectParentOption('local-services');
            this.selectOption(null);

            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Local Services</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showLocalServices} />
              </span>
            </span>
          </button>
          { this.state.showLocalServices ?localServicesChildCategories.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
          }): null}

          <button className={css.labelButton} onClick={() => {
            this.setState({       showLiveTalent: false, 
              showMusicAudio: false, 
              showMarketing: true, 
              showLocalServices: false, 
              showGraphicsDesign: false, 
              showBusiness: false  });
            this.selectParentOption('marketing');
            this.selectOption(null);


            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Marketing</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showMarketing} />
              </span>
            </span>
          </button>
          { this.state.showMarketing ?marketingChildCategories.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
            }): null}

          <button className={css.labelButton} onClick={() => {
            this.setState({       showLiveTalent: false, 
              showMusicAudio: true, 
              showMarketing: false, 
              showLocalServices: false, 
              showGraphicsDesign: false, 
              showBusiness: false  });
            this.selectParentOption('music-and-audio');
            this.selectOption(null);


            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Music & Audio</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showMusicAudio} />
              </span>
            </span>
          </button>
          { this.state.showMusicAudio ?musicAudioChildCategories.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
            }): null}

<button className={css.labelButton} onClick={() => {
            this.setState({ showLiveTalent: true, 
              showMusicAudio: false, 
              showMarketing: false, 
              showLocalServices: false, 
              showGraphicsDesign: false, 
              showBusiness: false  });
            this.selectParentOption('live-talent');
            this.selectOption(null);


            }}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>Live Talent</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.showLiveTalent} />
              </span>
            </span>
          </button>
          { this.state.showLiveTalent ?liveTalentChildCategories.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
            }): null}


          <button className={css.clearButton} onClick={e => {this.selectOption(null, e); this.selectParentOption(null, e);}}>
            <FormattedMessage id={'CategoryFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

CategoryFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

CategoryFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default CategoryFilterPlain;
