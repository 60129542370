import React from 'react';
import { bool } from 'prop-types';
import CategoryFilterPlain from './CategoryFilterPlain';
import CategoryFilterPopup from './CategoryFilterPopup';

const CategoryFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <CategoryFilterPopup {...rest} />
  ) : (
    <CategoryFilterPlain {...rest} />
  );
};

CategoryFilter.defaultProps = {
  showAsPopup: false,
};

CategoryFilter.propTypes = {
  showAsPopup: bool,
};

export default CategoryFilter;
